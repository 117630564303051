import postImage from "../../assets/images/postImage.png";
import ClockIcon from "../../assets/svgIcons/ClockIcon";
import ClockOutlineIcon from "../../assets/svgIcons/ClockOutlineIcon";
import FeedIcon from "../../assets/svgIcons/FeedIcon";
import TagIcon from "../../assets/svgIcons/TagIcon";
import TagOutlineIcon from "../../assets/svgIcons/TagOutlineIcon";
import ThreeDotsVerticalIcon from "../../assets/svgIcons/ThreeDotsVerticalIcon";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { timeSincePost } from "../../helperFunctions";
import api from "../../api";
import axios from "axios";
import { getAxiosConfig } from "../../endpoints/axios.config";
import { useSnackbar } from "notistack";

const tabs = ["All", "Latest", "Trending"];

const list = [
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
  {
    date: "April 24, 2024",
    groupName: "HFI Training",
    title:
      "Get certified by a world leader in user experience. Now at a special price ....",
    tags: ["training", "certification"],
    image: postImage,
  },
];

export const NewsFeedCard = ({ data }) => {
  // console.log("====================================");
  // console.log("THIS IS NEWS FEED ITEM", data);
  // console.log("====================================");

  return (
    <Link
      to={data?.dataValues?.link || data?.link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex flex-col hover:no-underline hover:bg-nwhited rounded-lg p-2.5 border gap-2 hover:text-light-black"
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-2">
            <FeedIcon className={"w-3 h-3"} fill="#6f6f6f" />
            <span className="font-montserrat text-xss">
              Sources : <span>{data?.source?.title || data?.title}</span>
            </span>
          </div>
          <div className="flex items-center gap-2">
            <ClockOutlineIcon className={"w-3 h-3"} fill={"#6f6f6f"} />
            <span className="font-montserrat text-xss">
              {timeSincePost(data?.dataValues?.pubDate || data?.pubDate)}
            </span>
          </div>
        </div>
        <ThreeDotsVerticalIcon className={"w-5 h-5"} fill={"#2e2e2e"} />
      </div>
      <div className="rounded bg-[#F7F7F7] border px-2 py-1">
        <span
          className="font-montserrat font-bold text-sm text-light-black line-clamp-1"
          dangerouslySetInnerHTML={{
            __html: data?.dataValues?.title || data?.title,
          }}
        />
      </div>
      {data?.tags?.length ? (
        <div className="flex items-center gap-1">
          <TagOutlineIcon className={"w-3 h-3"} fill={"#6f6f6f"} />
          <span className="font-montserrat text-xss">Tags: </span>
          <div className="flex items-center gap-3 flex-wrap">
            {data?.tags?.map((tag, tagIndex) => {
              return (
                <span className="font-montserrat font-medium text-xs italic text-[#AE82F6]">
                  #{tag?.tag}
                </span>
              );
            })}
          </div>
        </div>
      ) : null}
    </Link>
  );
};

const NewsFeed = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const page = useRef(0);

  const handlePublishHide = (id, deletedAt) => {
    axios.delete(api.deleteFeed(id), getAxiosConfig()).then(() => {
      if (deletedAt) {
        enqueueSnackbar("Feed is published!", { variant: "success" });
        alert("Feed is published!");
      } else {
        enqueueSnackbar("Feed is hidden!", { variant: "error" });
        alert("Feed is hidden!");
      }
      setRefresh(!refresh);
    });
  };

  const getFeeds = () => {
    setFetching(true);
    page.current += 1; // Increment the page number after fetching data
    const url = `${api.getFeeds("", true)}?page=${page.current}`;
    console.log(`Fetching feeds with URL: ${url}`);
    axios
      .get(url, getAxiosConfig())
      .then((res) => {
        setNewsList((prev) => {
          // if (
          //   prev.find(
          //     (item) => item?.dataValues?.id === res?.data?.[0]?.dataValues?.id
          //   )
          // ) {
          //   return [...prev];
          // }
          return [...prev, ...res.data];
        });
        setFetching(false);
       
         // Increment the page number after fetching data
      })
      .catch((error) => {
        console.error("Failed to fetch feeds:", error);
       
        setFetching(false);
      });
  };

  const onScroll = () => {
    const scrolledTo = window.scrollY + window.innerHeight;
    const isReachBottom = document.body.scrollHeight - scrolledTo <= 100; // Adjust threshold as needed

    if (isReachBottom && !fetching) {
      setFetching(true);
      getFeeds();
    }
  };

  useEffect(() => {
    getFeeds(); // Fetch the first page of feeds when the component mounts
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll); // Clean up the event listener on unmount
  }, []);


  return (
    <div className="flex flex-col rounded-lg bg-white shadow-nShadow p-3">
      {/* Tab container */}
      <div className="flex items-center gap-8">
        {/* {
				tabs?.map((tab, tabIndex) => {
					return <button
						onClick={() => setActiveTab(tabIndex)}
						className="flex flex-col flex items-center justify-center">
						<span className={`font-montserrat font-semibold ${activeTab === tabIndex ? 'text-light-black' : 'text-[#BABABA]'} `}>{tab}</span>
						{
							activeTab === tabIndex ?
								<div className="w-[90%] bg-pink h-0.5"></div>
								:
								null
						}
					</button>
				})
			} */}
      </div>
      {/* List container */}
      <div className="mt-4 flex flex-col gap-5 items-center justify-center">
        {newsList?.map((item, itemIndex) => {
          return (
            <div className="flex w-full items-center justify-center ">
              <div className="w-7/12">
              
                <NewsFeedCard data={item} />
              </div>
              <span
                onClick={() =>
                  handlePublishHide(
                    item.dataValues.id,
                    item?.dataValues?.deletedAt
                  )
                }
                className="cursor-pointer w-1/12 text-center pt-10 text-gray-500"
              >
                <a href="#">
                  {item?.dataValues?.deletedAt ? "Publish" : "Hide"}
                </a>
              </span>
            </div>
          );
        })}
      </div>
      <div className="p-2">{fetching && "Loading More..."}</div>
    </div>
  );
};

export default NewsFeed;
